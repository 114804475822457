import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactPageForm = ({ apiUrl }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    const recaptchaToken = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recaptchaVisible, setRecaptchaVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [formReady, setFormReady] = useState(false); // New state for form readiness

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);

        const allFieldsFilled = Object.values(updatedFormData).every(field => field.trim() !== '');
        setRecaptchaVisible(allFieldsFilled);

        // Update form readiness
        setFormReady(allFieldsFilled && recaptchaToken.current !== null);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName || formData.firstName.length > 100) {
            newErrors.firstName = 'First name is required and should be less than 100 characters.';
        }
        if (!formData.lastName || formData.lastName.length > 100) {
            newErrors.lastName = 'Last name is required and should be less than 100 characters.';
        }
        if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Valid email is required.';
        }
        if (!formData.phone || !/^\d{10,11}$/.test(formData.phone)) {
            newErrors.phone = 'Phone number is required and should be 10-11 digits.';
        }
        if (!formData.message || formData.message.length > 8000) {
            newErrors.message = 'Message is required and should be less than 8000 characters.';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        if (!recaptchaToken.current) {
            alert('Please complete the reCAPTCHA verification.');
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await fetch(`${apiUrl}/api/contact`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...formData, recaptchaToken: recaptchaToken.current }),
            });

            if (response.ok) {
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setErrors({});
                alert('Message sent successfully!');
                recaptchaToken.current = null;
                setRecaptchaVisible(false);
                setFormReady(false); // Reset form readiness
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (err) {
            console.error('Error submitting the form:', err);
            alert('Failed to send the message. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRecaptchaChange = (token) => {
        recaptchaToken.current = token;

        // Update form readiness when recaptcha is completed
        const allFieldsFilled = Object.values(formData).every(field => field.trim() !== '');
        setFormReady(allFieldsFilled && token !== null);
    };

    return (
        <form className="estimate-form" id="contact-form" onSubmit={handleSubmit}>
            <div className="contact-form-input-wrapper half">
                <label>First Name</label>
                <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    maxLength={100}
                    required
                />
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
            </div>

            <div className="contact-form-input-wrapper half">
                <label>Last Name</label>
                <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    maxLength={100}
                    required
                />
                {errors.lastName && <div className="error-message">{errors.lastName}</div>}
            </div>

            <div className="contact-form-input-wrapper half">
                <label>Email</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    maxLength={150}
                    required
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
            </div>

            <div className="contact-form-input-wrapper half">
                <label>Phone</label>
                <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    inputMode="numeric"
                    maxLength={11}
                    pattern="\d{10,11}"
                    title="Phone number should be 10-11 digits"
                    required
                />
                {errors.phone && <div className="error-message">{errors.phone}</div>}
            </div>

            <div className="contact-form-input-wrapper full">
                <label>Message</label>
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    maxLength={8000}
                    required
                />
                {errors.message && <div className="error-message">{errors.message}</div>}
            </div>

            {recaptchaVisible && (
                <div className="captcha-wrapper">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        onChange={handleRecaptchaChange}
                    />
                </div>
            )}

            <div className="form-submit-wrapper">
                <button type="submit" className="estimate-button" disabled={isSubmitting || !formReady}>
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                </button>
            </div>
        </form>
    );
};

export default ContactPageForm;