import React from 'react';
import './Hero.css';
import HeroContactForm from '../HeroContactForm/HeroContactForm';

const Hero = () => {
  return (
    <div className='hero-wrapper'>
      <div className='hero-content-wrapper'>
        <div className='hero-content'>
          <div className='free-estimate'>
            <div className='estimate-header'>
              <p><span>Free No-Obligation Estimates</span></p>
            </div>
            <div className='estimate-button-wrapper'>
              <a href="tel:352-812-0831" aria-label="Call Watkins Seamless Gutters" className='estimate-button'><span className="text">Call Us Today!</span></a>
            </div>
          </div>
          <div className='form-background'>
            <div className='form-container'>
              <div className='form-content-wrapper'>
                <HeroContactForm apiUrl={process.env.REACT_APP_API_URL} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
