import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const HeroContactForm = ({ apiUrl }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    projectNeeds: '',
    additionalInfo: '',
  });
  const recaptchaToken = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaVisible, setRecaptchaVisible] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    const allFieldsFilled = Object.values(updatedFormData).every(field => field.trim() !== '');
    setRecaptchaVisible(allFieldsFilled);
    setFormReady(allFieldsFilled && recaptchaToken.current !== null);
  };

  const handleRecaptchaChange = (token) => {
    recaptchaToken.current = token;
    const allFieldsFilled = Object.values(formData).every(field => field.trim() !== '');
    setFormReady(allFieldsFilled && token !== null);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName || formData.firstName.length > 100) {
      newErrors.firstName = 'First name is required and should be less than 100 characters.';
    }
    if (!formData.lastName || formData.lastName.length > 100) {
      newErrors.lastName = 'Last name is required and should be less than 100 characters.';
    }
    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Valid email is required.';
    }
    if (!formData.phone || !/^\d{10,11}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number is required and should be 10-11 digits.';
    }
    if (!formData.address || formData.address.length > 250) {
      newErrors.address = 'Address is required and should be less than 250 characters.';
    }
    if (!formData.projectNeeds || formData.projectNeeds.length > 8000) {
      newErrors.projectNeeds = 'Project needs are required and should be less than 8000 characters.';
    }
    if (formData.additionalInfo.length > 8000) {
      newErrors.additionalInfo = 'Additional info should be less than 8000 characters.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    if (!recaptchaToken.current) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${apiUrl}/api/new-inquiry`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, recaptchaToken: recaptchaToken.current }),
      });

      if (response.ok) {
        alert('Inquiry sent successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: '',
          projectNeeds: '',
          additionalInfo: '',
        });
        recaptchaToken.current = null;
        setRecaptchaVisible(false);
        setFormReady(false);
      } else {
        alert('Error submitting the form.');
      }
    } catch (err) {
      console.error('Error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="estimate-form" onSubmit={handleSubmit}>
      <div className="contact-form-input-wrapper half">
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          maxLength={100}
          required
        />
        {errors.firstName && <div className="error-message">{errors.firstName}</div>}
      </div>

      <div className="contact-form-input-wrapper half">
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          maxLength={100}
          required
        />
        {errors.lastName && <div className="error-message">{errors.lastName}</div>}
      </div>

      <div className="contact-form-input-wrapper half">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          maxLength={150}
          required
        />
        {errors.email && <div className="error-message">{errors.email}</div>}
      </div>

      <div className="contact-form-input-wrapper half">
        <label htmlFor="phone">Phone</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          inputMode="numeric"
          maxLength={11}
          pattern="\d{10,11}"
          title="Phone number should be 10-11 digits"
          required
        />
        {errors.phone && <div className="error-message">{errors.phone}</div>}
      </div>

      <div className="contact-form-input-wrapper full">
        <label htmlFor="address">Address</label>
        <input
          type="text"
          id="address"
          name="address"
          value={formData.address}
          onChange={handleChange}
          maxLength={250}
          required
        />
        {errors.address && <div className="error-message">{errors.address}</div>}
      </div>

      <div className="contact-form-input-wrapper full">
        <label htmlFor="projectNeeds">Project Needs</label>
        <input
          type="text"
          id="projectNeeds"
          name="projectNeeds"
          value={formData.projectNeeds}
          onChange={handleChange}
          maxLength={8000}
          required
        />
        {errors.projectNeeds && <div className="error-message">{errors.projectNeeds}</div>}
      </div>

      <div className="contact-form-input-wrapper full">
        <label htmlFor="additionalInfo">Additional Information</label>
        <textarea
          id="additionalInfo"
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          maxLength={8000}
        />
        {errors.additionalInfo && <div className="error-message">{errors.additionalInfo}</div>}
      </div>

      {recaptchaVisible && (
        <div className="captcha-wrapper">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleRecaptchaChange}
          />
        </div>
      )}

      <div className="form-submit-wrapper">
        <button type="submit" className="estimate-button" disabled={isSubmitting || !formReady}>
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
      </div>
    </form>
  );
};

export default HeroContactForm;
